// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../waterbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mitr&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "* {\n  box-sizing: border-box;\n}\n\n.bg{\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 120vh;\n\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: 'absolute',\n}\n\nbody {\n  min-height: 100vh;\n  display: flex;\n  font-weight: 400;\n  font-family: 'Kanit', sans-serif;\n}\n\ndiv.signin,div.regis{\n  background-color: rgba(250, 250, 250, 0.952);\n}\n\nh3,label,span {\n  font-weight: 500;\n  font-family: 'Kanit', sans-serif;\n}\n\nbody, html, .App, #root {\n  width: 100%;\n  height: 100%;\n  \n}\n\n.forgot-password,\n.forgot-password a {\n  text-align: left;\n  font-size: 13px;\n  padding-top: 10px;\n  color: #7f7d7d;\n  margin: 0;\n  font-weight: 300;\n  font-family: 'Kanit', sans-serif;\n}\n\n.forgot-password a {\n  color: #2b64af;\n}\n\n.form-control,.btn{\n  font-weight: 300;\n  font-family: 'Kanit', sans-serif;\n}\n\n.btn{\n  background: #ffffff;\n}\n\n#login{\n    width: 400px;\n    height: 400px;\n    margin-top: 100px;\n    margin-left: auto; \n    margin-right: 35px;\n\n}\n\n#register{\n  width: 400px; \n  height: 400px;\n  margin-top: 30px;\n  margin-left: auto; \n  margin-right: 35px;\n}\n\n#formregister{\n  margin: 40px;\n}\n#formlogin{\n  margin: 40px;\n}\n\nbutton#login{\n  background-color: #4f72b4;\n}\n\nbutton#register{\n  background-color: #4f72b4;\n}\n\n.alignleft {\n  float: left;\n  width:33.33333%;\n  text-align:left;\n}\n.aligncenter {\n  float: left;\n  width:33.33333%;\n  text-align:center;\n}\n.alignright {\n float: left;\n width:33.33333%;\n text-align:right;\n}\n\n.user{\n  width: 36px;\n  height: 36px;\n}\n", ""]);
// Exports
module.exports = exports;
