import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRouter from './Router'

import './assets/css/main.css'

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
