// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mitr&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500&display=swap);"]);
// Module
exports.push([module.id, "body {\n    background: #ffffff !important;\n    min-height: 100vh;\n    display: flex;\n    text-align: center;\n    font-weight: 400;\n    font-family: 'Kanit', sans-serif;\n  }\n\n  h2{\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n    text-align: center;\n  }\n\n  h3,label,span {\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n  }\n\n  body, html, .App, #root {\n    width: 100%;\n    height: 100%;\n\n  }\n\n  div.card-header{\n    font-size: 18pt;\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n    background-color: #4f72b4;\n    color: #ffffff ;\n  }\n\n  div.card{\n    border-color: #4f72b4;\n  }\n\n  .card-body{\n    font-size: 12pt;\n    font-weight: 300;\n    font-family: 'Kanit', sans-serif;\n  }\n\n  div.card-footer{\n    background-color: #ffffff;\n  }\n\n  /* button.btn{\n    background-color: #4f72b4;\n  } */\n\n  button.darker, button.darker:focus{\n    background-color: #4062a1;\n  }\n\n  button.del{\n    background-color: #B54242;\n  }\n\n\n  button.add{\n    background-color: #E4E4E4;\n    border: none;\n  }\n\n  button.add:hover,button.add:active{\n    background-color: #E4E4E4;\n    border: none;\n  }\n\n  span.badge.head{\n    background-color: #fff4a2;\n    color: rgb(44, 44, 44);\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n  }\n\n div.modal-header, div.modal-body{\n  font-weight: 500;\n  font-family: 'Kanit', sans-serif;\n }\n\nbutton.cancel{\n  background-color: #F4B03B;\n}\n\n\n.input-group#check{\n  /* background-color: #F4B03B; */\n  width: 400px;\n}\n\n.input-range {\n  margin-top: 20px;\n}\n", ""]);
// Exports
module.exports = exports;
