// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mitr&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500&display=swap);"]);
// Module
exports.push([module.id, "body{\n    text-align: center;\n}\n#root{\n    background-color: #ffffff;\n}\n\n.label{\n    text-align: left;\n}\n\n\nbutton#find{\n    background-color: #4f72b4;\n    justify-content: center;\n    align-items: center;\n}\n  \nthead th{ \n    background-color: #4f72b4; \n    color: rgb(255, 255, 255);\n    /* color: rgb(0, 0, 0); */\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n}\n\nthead.user th{ \n    background-color: #779de2; \n    color: rgb(255, 255, 255);\n    /* color: rgb(0, 0, 0); */\n    font-weight: 500;\n    font-family: 'Kanit', sans-serif;\n}\n\nth{\n    font-weight: 400;\n    font-family: 'Kanit', sans-serif;\n}\n\nbutton#pH,button#turbidity,\nbutton#temp,button#do{\n    background-color: #ffffff;\n    color: rgb(0, 0, 0);\n}\n\nbutton#pH.active,button#turbidity.active,\nbutton#temp.active,button#do.active{\n    background-color: #4f72b4;\n    color: rgb(255, 255, 255);\n}\n\nbutton.save{\n    background-color: #4f72b4;\n    color: rgb(255, 255, 255);\n}\n\nbutton.now{\n    background-color: #F4B03B;\n    color: rgb(255, 255, 255);\n}\n\nbutton.save:hover,button.now:hover{\n    background-color: #9de0a0;\n}\n\n.input-group{\n    align-items: center;\n}\n  \n.row#choose{\n    height: 90px;\n}\n", ""]);
// Exports
module.exports = exports;
