// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mitr&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500&display=swap);"]);
// Module
exports.push([module.id, "body{\n    text-align: center;\n    \n}\n#root{\n    background-color: white;\n}\n\n.navbar{\n    font-family: 'PT Sans Caption', sans-serif;\n    margin: 10px;\n    background-color: white;\n    box-shadow: black;\n}\n.navbar-brand{\n    margin-left: 10px;\n}\n\n.nav-item{\n    margin: 10px;\n    font-weight: 400;\n    font-family: 'Kanit', sans-serif;\n\n}\n\n.nav-item a.nav-link.rounded-pill:hover{\n    color: #4f72b4;\n}\n\n.nav-item a.nav-link.rounded-pill.rounded-pill-active{\n    background-color: #4f72b4;\n    color: rgb(255, 255, 255);\n    \n}\n\n\n\n", ""]);
// Exports
module.exports = exports;
